

#root, .App {
  height: 100%;
  width: 100%;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  position: absolute;
}

.rbc-day-slot .rbc-event-label {
  display: none;
}

.custom-event {
  height: 100%;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: 6;
  padding: 2px 5px;
  font-size: 10px;
}

.rbc-event {
  background-color: #fff;
  padding: 0;
}

.rbc-day-slot .rbc-event {
  border: 0;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0;
}